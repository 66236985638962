import React from 'react';
import './App.css';
import MainRouter from './route/mainRouter';

function App() {

  return (
    <MainRouter />
  )
}

export default App;
